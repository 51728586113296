<template>
  <student-form
    :is-visible="isVisible"
    @update-triggered="triggerUpdateStudent()"
    title="Data Inti"
  >
    <form action="#" @submit.prevent="triggerUpdateStudent()">
      <!-- Student Name -->
      <label class="form-label" for="student_name">Nama</label>
      <input type="text" id="student_name" v-model="form.name" />

      <!-- Student Gender -->
      <span class="form-label">Jenis kelamin</span>
      <!-- Male -->
      <input
        type="radio"
        id="student_male"
        name="gender"
        value="1"
        v-model="form.gender"
      />
      <label class="ml-2 mr-6" for="student_male">Laki-laki</label>
      <!-- Female -->
      <input
        type="radio"
        id="student_female"
        name="gender"
        value="0"
        v-model="form.gender"
      />
      <label class="ml-2" for="student_female">Perempuan</label>

      <!-- NISN -->
      <label class="form-label mt-4" for="student_nisn">NISN (Jika Ada)</label>
      <input type="text" id="student_nisn" v-model="form.nisn" />

      <!-- NIK -->
      <label class="form-label" for="student_nik">NIK</label>
      <input type="text" id="student_nik" v-model="form.nik" />

      <!-- Birth Place -->
      <label class="form-label" for="student_bp">tempat lahir</label>
      <input type="text" id="student_bp" v-model="form.birth_place" />

      <!-- Birth date -->
      <label class="form-label" for="student_bd">tanggal lahir</label>
      <datetime
        id="student_bd"
        title="Tanggal Lahir"
        placeholder="Tanggal Lahir"
        class="custom-datepicker"
        :use12-hour="false"
        v-model="form.birth_date"
        :auto="true"
      ></datetime>
      <!-- <input type="text" id="student_bd" v-model="form.birth_date" /> -->

      <!-- Sibling positoin -->
      <label class="form-label" for="student_sp">anak ke</label>
      <input type="text" id="student_sp" v-model="form.sibling_position" />

      <!-- Sibling total -->
      <label class="form-label" for="student_st">jumlah saudara kandung</label>
      <input type="text" id="student_st" v-model="form.sibling_total" />

      <!-- previous school -->
      <label class="form-label" for="student_previous_school"
        >sekolah sebelumnya (jika pindahan)</label
      >
      <input type="text" id="student_previous_school" />

      <span class="mt-8 mb-4 block tracking-wider uppercase font-bold"
        >Keadaan Jasmani</span
      >
      <!-- Serious Ilness -->
      <label class="form-label" for="student_si">Penyakit Berat</label>
      <input type="text" id="student_si" v-model="form.serious_illness" />

      <!-- Dietary -->
      <label class="form-label" for="student_dr">Pantangan makanan</label>
      <input type="text" id="student_dr" v-model="form.dietary_restriction" />

      <!-- height -->
      <label class="form-label" for="student_he">Tinggi Badan</label>
      <input type="text" id="student_he" v-model="form.height" />

      <!-- Weight -->
      <label class="form-label" for="student_we">Berat Badan</label>
      <input type="text" id="student_we" v-model="form.weight" />
    </form>
  </student-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";
import _clone from "lodash/clone";

export default {
  data() {
    return {
      form: {},
      formDefault: {},
    };
  },
  methods: {
    async triggerUpdateStudent() {
      await this.updateStudent(this.form);
    },

    ...mapMutations("student", ["HIDE_FORM"]),
    ...mapActions("student", ["updateStudent"]),
  },
  watch: {
    selected_form(val) {
      if (val === "core") {
        this.form = _clone(this.selected_student);
        this.form.address = [];
        this.form.parents = [];
      } else {
        this.form = _clone(this.formDefault);
      }
    },
  },
  computed: {
    isVisible() {
      return this.form_visibility === true && this.selected_form === "core";
    },

    ...mapGetters("student", [
      "selected_form",
      "form_visibility",
      "selected_student",
    ]),
  },
  mounted() {
    this.formDefault = _clone(this.form);
    Settings.defaultLocale = "id";
  },
  components: {
    Datetime,
    StudentForm: () =>
      import("@/components/Student/InformationForm/StudentForm.vue"),
  },
  name: "CoreForm",
};
</script>
